<template>
  <div>
    <b-row class="match-height">
      <b-col md="6">
        <b-card>
          <label>Balai / Bidang</label>
          <b-form-group>
            <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
              :options="rsbalaibidang" @input="DataLoad(selected.id_bidang)" />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <statistik :rs_data=rs_data @LoadDataUlang="LoadDataUlang"></statistik>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <tabel-data :rs_data=rs_data.rs @detailData="detailData" :validasi="validasi"></tabel-data>
        </b-card>
      </b-col>
    </b-row>
    <detail_ajuan :rs_detail=rs_detail @BeriCatatan=BeriCatatan @validasiPengajuan=validasiPengajuan
      @batalkanValidasi="batalkanValidasi"></detail_ajuan>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BRow, BCol, BCard
} from 'bootstrap-vue'
import statistik from './component/statistik.vue'
import TabelData from './component/tabelData.vue'
import detail_ajuan from './component/DetailPengajuan.vue'
export default {
  components: {
    BTabs, vSelect, detail_ajuan,
    BTab, BRow, BCol, BCard,
    statistik,
    TabelData
  },
  props: {

  },
  data() {
    return {
      rs_data: [],
      id_balai_bidang: "",
      rsbalaibidang: [],
      validasi: '',
      rs_detail: {},
      selected: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data()
    this.balai_bidang()

  },
  methods: {
    LoadDataUlang(val) {
      this.validasi = val;
      this.load_data()
    },
    detailData(val) {
      this.rs_detail = val
    },
    DataLoad() {
      this.id_balai_bidang = this.selected.id_bidang;
      this.load_data()
    },
    async batalkanValidasi(id_usulan_kegiatan, catatan) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/validasi/batalkan_validasi',
        data: {
          id_usulan_kegiatan, catatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.load_data();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });

    },
    async BeriCatatan(id_usulan_kegiatan, catatan) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/validasi/validasi_pagu_anggaran',
        data: {
          id_usulan_kegiatan, catatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.load_data();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });

    },
    async validasiPengajuan(id_usulan_kegiatan, keterangan, pengajuanAnggaran) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/validasi/validasi_pagu_anggaran',
        data: {
          id_usulan_kegiatan: id_usulan_kegiatan,
          keterangan: keterangan,
          anggaran_validasi: pengajuanAnggaran,
          validasi: '1'
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.load_data();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });

    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/validasi/load_data',
        data: {
          id_balai_bidang: self.id_balai_bidang,
          validasi: self.validasi
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async balai_bidang() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/validasi/balai_bidang',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsbalaibidang = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>