<template>
  <div>
    <h5 class="text-warning"><strong><b-avatar rounded="" size="20" variant="warning"><feather-icon icon="BellIcon"
            size='15' /></b-avatar> Catatan Pengajuan Pagu Anggaran
      </strong></h5>
    <i v-if="note == ''">Belum ada catatan pagu anggaran ini ..........</i>
    <b-card v-for="note in note" v-bind:key="note">
      <b-row>
        <b-col md="12">
          <h6>Note : {{ note.keterangan }}</h6>
          <div v-if="note.status == 'Revisi' && note.anggaran_awal != '0' && note.anggaran_validasi != '0'">
            <h6>Anggaran Di Koreksi dari <sup>Rp</sup> {{ Rp(note.anggaran_awal) }} menjadi <sup>Rp</sup> {{
              Rp(note.anggaran_validasi) }}</h6>
          </div>
          <div v-if="note.status == 'Acc' && note.anggaran_awal != '0' && note.anggaran_validasi != '0'">
            <b-row>
              <b-col md="7">Anggaran yang di validasi</b-col><b-col md="5"><strong class="text-primary"><sup>Rp</sup> {{
                Rp(note.anggaran_validasi)
              }}</strong></b-col>
              <b-col md="7">Anggran yang di usulkan</b-col><b-col md="5"><sup>Rp</sup> {{
                Rp(note.anggaran_awal) }}</b-col>
            </b-row>
          </div>
          <small class="text-muted"><i> User {{ note.nm_lengkap }}<br>Tanggal {{ note.tgl_note
          }}</i></small>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab, BRow, BCol, BCard, BAvatar
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BTabs,
    BTab, BRow, BCol, BCard, BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    note: {},
    title: {}
  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  }

}
</script>