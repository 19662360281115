<template>
  <div>
    <b-modal id="modal-1" title="Detail Ajuan" ok-disabled no-close-on-backdrop size="xl" hide-footer>
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="8">
              <h6 class="text-primary"><feather-icon icon="GridIcon" size='15' /> Rincian Usulan Kegiatan </h6>
              <b-row>
                <b-col md="6">
                  <small class="text-primary">Nama Balai/BPJ <br><strong>{{ rs_detail.nm_balai }}</strong> </small>
                  <h6 class="text-primary"><strong>{{ rs_detail.kode_sub_kegiatan }}</strong><small
                      style="text-align: justify;">
                      {{ rs_detail.nama_sub_kegiatan }}
                    </small></h6>
                  <h6 class="text-primary"> <small> Nama Pekerjaan </small>
                    <br>
                    <small style="text-align: justify;"><strong>{{ rs_detail.nama_pekerjaan }}</strong> </small>
                    <br>
                    <small> Rincian Pekerjaan </small>
                    <br>
                    <small style="text-align: justify;"><strong>{{ rs_detail.satuan_target_pekerjaan }} </strong></small>
                  </h6>

                  <h6 class="text-primary">
                    <small> Jenis Pekerjaan <br><strong> {{ rs_detail.nm_jenis_pekerjaan }} </strong></small>
                  </h6>
                </b-col>
                <b-col md="6">
                  <h6>
                    <small class="text-primary">Ruas Jalan </small><br>
                    <small class="text-primary"> <strong>{{ rs_detail.nama_ruas_jalan }} {{
                      rs_detail.nama_wilayah }} </strong><br>
                      <small>{{ rs_detail.detail_lokasi }} </small>
                    </small>
                  </h6>
                  <b-row>
                    <b-col md="6">
                      <h6 class="text-primary">
                        <small class="text-primary">Target Pekerjaan</small><br>
                        <strong>{{ rs_detail.target_pekerjaan }} 20 KM
                        </strong>
                      </h6>
                    </b-col>
                    <b-col md="6">
                      <h4 class="text-primary">
                        <small>Pagu Usulan</small><br>
                        <strong style="font-size: 20px;"> <sup><small>Rp.</small></sup> {{ Rp(rs_detail.pagu_usulan) }}
                        </strong>
                      </h4>
                    </b-col>
                  </b-row>
                  <br>
                  <b-button variant="primary" block size="sm" v-if="rs_detail.validasi == '1'">
                    <feather-icon icon="CheckIcon" size='15' /> Pengajuan Tervalidasi
                  </b-button>

                  <b-button variant="warning" block size="sm" v-if="rs_detail.validasi == '0'">
                    <feather-icon icon="RefreshCcwIcon" size='15' /> Pengajuan
                  </b-button>

                </b-col>
              </b-row>


            </b-col>
            <b-col md="4">
              <h6 class="text-primary"><feather-icon icon="GridIcon" size='15' /> Rincian Skor Penilaian </h6>
              <ul>
                <li v-for="pn in rs_detail.penilaian_manual_ajuan" v-bind:key="pn" style="margin-top: 5px;">
                  <b-row>
                    <b-col md="8">{{ pn.nama }}</b-col> <b-col md="4"> {{ pn.bobot }}</b-col>
                  </b-row>
                </li>
                <li v-for="pn in rs_detail.nilai_tambah_jalan" v-bind:key="pn" style="margin-top: 5px;">
                  <b-row>
                    <b-col md="8">{{ pn.nama }}</b-col> <b-col md="4"> {{ pn.bobot }}</b-col>
                  </b-row>
                </li>
                <li v-for="pn in rs_detail.manual_penilaian" v-bind:key="pn" style="margin-top: 5px;">
                  <b-row>
                    <b-col md="8">{{ pn.nama }}</b-col> <b-col md="4"> {{ pn.bobot }}</b-col>
                  </b-row>
                </li>
              </ul>
              <b-row>
                <b-col md="8">Total Skor Penilaian</b-col> <b-col md="4"> <strong class="ml-1">{{
                  Rp(rs_detail.bobot_nilai)
                }}</strong></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col md="4">
          <note :note=rs_detail.note :title="'Note Validasi'"></note>

          <note_anggaran :note=rs_detail.koreksi_anggaran :title="'Note Pagu Anggaran'"></note_anggaran>
        </b-col>
        <b-col md="8" v-if="rs_detail.validasi == '1'">
          <h5> <feather-icon icon="GridIcon" size='15' /> Batalkan Validasi</h5>
          <validation-observer ref="batalValidasi">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group label="" label-for="Koreksi Anggaran">
                    <validation-provider #default="{ errors }" rules="required"
                      name="Alasan Validasi data Dibatalkan....">
                      <b-form-textarea v-model="input.koreksi" :state="errors.length > 0 ? false : null"
                        placeholder="Alasan Validasi data Dibatalkan...."></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button variant="warning" type="submit" size="sm" @click.prevent="batalkanValidasi">
                    <feather-icon icon="RefreshCcwIcon" size='15' />
                    Batalkan Validasi Pengajuan Anggaran
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
        <b-col md="8" v-if="rs_detail.validasi == '0'">
          <h5> <feather-icon icon="GridIcon" size='15' /> Beri Catatan Pagu Anggaran</h5>
          <validation-observer ref="simpleRulesNote">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group label="" label-for="Koreksi Anggaran">
                    <validation-provider #default="{ errors }" rules="required" name="Catatan Koreksi Pengajuan Anggrana">
                      <b-form-textarea v-model="input.koreksi" :state="errors.length > 0 ? false : null"
                        placeholder="Catatan Koreksi Pengajuan Anggrana"></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button variant="warning" type="submit" size="sm" @click.prevent="validationFormNote"> <feather-icon
                      icon="EditIcon" size='15' />
                    Beri Catatan Pengajuan Anggaran
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <hr>
          <h5> <feather-icon icon="GridIcon" size='15' /> Validasi Pagu Anggaran</h5>
          <validation-observer ref="simpleRules" v-if="rs_detail.validasi == '0'">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group label="Usulan Anggaran yang disetujui" label-for="username">
                    <validation-provider #default="{ errors }" rules="required" name="Pagu Anggaran Yang Di Stujui">
                      <currency-input class="form-control " v-model="pengajuanAnggaran"
                        :state="errors.length > 0 ? false : null" placeholder="Pagu Anggaran Yang Di Stujui" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group label="Catatan Koreksi Pengajuan Anggaran" label-for="Koreksi Anggaran">
                    <validation-provider #default="{ errors }" rules="required" name="Keterangan Pengajuan Anggaran">
                      <b-form-textarea v-model="input.keterangan" :state="errors.length > 0 ? false : null"
                        placeholder="Keterangan Pengajuan Anggaran wajib di input"></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>




                </b-col>

                <b-col cols="12">
                  <b-button variant="primary" size="sm" type="submit" @click.prevent="validationForm">
                    <feather-icon icon="CheckIcon" size='15' /> ACC Pengajuan
                  </b-button>
                  <b-button variant="warning" size="sm" class="ml-1" @click="close()"><feather-icon icon="RefreshCcwIcon"
                      size='15' />
                    Keluar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BBadge, BAvatar, BFormGroup, BFormInput, BFormTextarea } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Note from './note.vue'
import note_anggaran from './note_anggaran.vue'
import CurrencyInput from './CurrencyInput.vue'

export default {
  components: {
    BButton, BAvatar, BFormGroup, BFormInput, BFormTextarea,
    BModal, BBadge, note_anggaran,
    BAlert, BRow, BCol, Note, ValidationProvider, ValidationObserver,
    CurrencyInput
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required, input: {}
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["BeriCatatan", 'validasiPengajuan'],
  methods: {
    validationFormNote() {
      this.$refs.simpleRulesNote.validate().then(success => {
        if (success) {
          this.$emit('BeriCatatan', this.rs_detail.id_usulan_kegiatan, this.input.koreksi);
          this.input = {}
          this.close();
        }
      })
    },
    batalkanValidasi() {
      this.$refs.batalValidasi.validate().then(success => {
        if (success) {
          if (confirm("Apakah anda yakin akan membatalkan validas ajuan ini.....")) {
            this.$emit('batalkanValidasi', this.rs_detail.id_usulan_kegiatan, this.input.koreksi);
            this.input = {}
            this.close();
          }


        }
      })
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (confirm("Apakah anda yakin akan mevalidasi ajuan ini.....")) {
            this.$emit('validasiPengajuan', this.rs_detail.id_usulan_kegiatan, this.input.keterangan, this.pengajuanAnggaran)
            this.input = {}
            this.close();
          }
        }
      })
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    rs_detail: {}
  },
  computed: {
    pengajuanAnggaran: {
      get: function () {
        return this.rs_detail.pagu_usulan;
      },
      set: function (newValue) {
        return this.rs_detail.pagu_usulan = newValue;
      }
    },

  },
}
</script>
<style scoped>
.bg_w {
  background: #f9fcff !important
}
</style>