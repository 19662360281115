<template>
  <b-row>

    <b-col md="7" sm="8" class="my-1">
      <h5 class="text-primary"> <feather-icon icon="GridIcon" size='15' /> Daftar Paket
        <span v-if="validasi == '1'">Tervalidasi</span>
        <span v-else-if="validasi == '0'">Belum Tervalidasi</span>
        <span></span>
      </h5>
    </b-col>
    <b-col md="1" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
      </b-form-group>
    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="4">
              <h6 class="text-primary"><small class="text-primary">Nama Balai/BPJ <br></small><strong>{{
                data.item.nm_balai
              }}</strong>
              </h6>
              <small class="text-primary">Nama Sub Kegiatan</small>
              <h6 class="text-primary"> <small><strong>{{ data.item.kode_sub_kegiatan }} {{ data.item.nama_sub_kegiatan
              }}</strong></small> <small style="text-align: justify;">

                </small></h6>

            </b-col>
            <b-col md="6">
              <h6 class="text-primary">
                <!-- <small><strong>Nama Pekerjaan</strong> </small> -->
                <!-- <br> -->
                <small style="text-align: justify;"> <strong>Nama pekerjaan :</strong> {{ data.item.nama_pekerjaan
                }}</small>
                <br>
                <small><strong>Jenis Pekerjaan : </strong> {{ data.item.nm_jenis_pekerjaan }}</small><br>
                <small class="text-primary">Ruas Jalan : </small>
                <small class="text-primary"> <strong>{{ data.item.nama_ruas_jalan }} Wilayah
                    {{ data.item.nama_wilayah }}
                  </strong><br>
                  <small>Detail Lokasi : {{ data.item.detail_lokasi }} Target Pekerjaan <strong>{{
                    data.item.target_pekerjaan }} {{ data.item.satuan }}</strong></small>
                </small>

              </h6>

              <h6>
                <small> <strong></strong></small>
              </h6>
              <h6>

              </h6>

            </b-col>
            <b-col md="2" class="text-center">
              <h6 class="text-center">Skor Penilaian</h6>
              <h6 class="text-bold text-primary text-center" style="font-size: 30px;"> {{ data.item.bobot_nilai }}
              </h6>
              <b-badge block variant="primary" size="sm" v-b-modal.modal-1 @click="detailData(data.item)">
                <feather-icon icon="GridIcon" class="mr-50" size='15' /> Detail Ajuan
              </b-badge>

            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],


    }
  },
  props: {
    rs_data: {},
    validasi: {}
  },
  computed: {
    items: {
      get: function () {
        return this.rs_data;
      },
      set: function (newValue) {
        return this.rs_data = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  emits: ['detailData'],
  methods: {
    detailData(val) {
      this.$emit('detailData', val)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>