<template>
  <div>
    <h5 class="text-primary"><strong><b-avatar rounded="" size="20" variant="primary"><feather-icon icon="BellIcon"
            size='15' /></b-avatar> {{ title }}
      </strong></h5>
    <i v-if="note == ''">Belum ada Catatan terkait anggaran ini ..........</i>
    <app-timeline>

      <app-timeline-item v-for="note in note" v-bind:key="note" :title="note.catatan" :subtitle="note.dc"
        variant="success" />

    </app-timeline>
  </div>
</template>

<script>
import {
  BTabs,
  BTab, BRow, BCol, BCard, BAvatar
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BTabs,
    BTab, BRow, BCol, BCard, BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    note: {},
    title: {}
  }
}
</script>