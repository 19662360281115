<template>
  <div>
    <b-row>
      <b-col md="6" class="text-center">
        <div class="card " @click="LoadDataUlang('')">
          <div class="card-header">
            <div class="avatar bg-light-primary text-white p-50 m-0">
              <b-avatar size="50" variant="primary">
                <feather-icon icon="BellIcon" size='15' />
              </b-avatar>
            </div>
            <div>
              <h2 class="font-weight-bolder text-primary mb-0">{{ rs_data.jm_total }}</h2>
              <p class="card-text">Total Paket</p>
            </div>
          </div>
        </div>
      </b-col>
      <!-- <b-col md="4" class="text-center">
        <div class="card " @click="LoadDataUlang('0')">
          <div class="card-header">
            <div class="avatar bg-light-primary text-white p-50 m-0">
              <b-avatar size="50" variant="warning">
                <feather-icon icon="BellIcon" size='15' />
              </b-avatar>
            </div>
            <div>
              <h2 class="font-weight-bolder  mb-0">{{ rs_data.jm_belum }}</h2>
              <p class="card-text">Pengajuan Belum</p>
            </div>
          </div>
        </div>
      </b-col> -->
      <b-col md="6" class="text-center">
        <div class="card " @click="LoadDataUlang('1')">
          <div class="card-header">
            <div class="avatar bg-light-primary text-primary p-50 m-0">
              <b-avatar size="50" variant="info">
                <feather-icon icon="BellIcon" size='15' />
              </b-avatar>
            </div>
            <div>
              <h2 class="font-weight-bolder text-primary mb-0">{{ rs_data.jm_validasi }}</h2>
              <p class="card-text">Paket Tervalidasi</p>
            </div>

          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody, BAlert
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },

      ],
    }
  },
  props: {
    rs_data: {}
  },
  emits: ['LoadDataUlang'],
  methods: {
    LoadDataUlang(val) {
      this.$emit('LoadDataUlang', val)
    }

  }
}
</script>
